import { request } from 'component/mux';

//region - [공통]
const TRANSID_AUTHENTICATION = {
    fetch: {
        url: '/user/profile',
        method: 'get',
    },
    join: {
        url: '/user/join',
        method: 'post',
    },
    agree: {
        url: '/user/agree',
        method: 'post',
    },
    signUp: (param) => {
        const isLocal = 'local' === process.env.REACT_APP_MODE;
        const { type, code, state } = param;

        const url = isLocal ? `/user/auth2` : `/user/auth`;
        const data = isLocal ? param : { code, state, type };
        return request({
            url: url,
            method: 'post',
            data: data,
        }).then((response) => {
            const { status, data } = response;
            if (200 !== status) {
                throw response;
            }

            // const { activationStatus } = data;
            if (data?.activationStatus) {
                return response;
            }
            throw response;
        });
    },
    signOut: {
        url: '/user/auth',
        method: 'delete',
    },
    fetchState: (param) => {
        const { clientID, redirectURI } = param;
        return request({
            url: `/user/login/state?clientID=${clientID}&redirectURI=${redirectURI}`,
            method: 'get',
        });
    },
    tokenPublish: (param) => {
        const { type, code, state } = param;
        return request({
            url: '/user/token',
            method: 'post',
            data: { code, state, type },
        });
    },
    validatePin: (param) => {
        const { pin, nationality } = param;
        return request({
            url: '/user/pin',
            method: 'post',
            data: { pin, nationality },
        }).then((response) => {
            const { status, data } = response;
            if (200 !== status) {
                throw response;
            }

            // const { activationStatus } = data;
            if (data?.activationStatus) {
                return response;
            }
            throw response;
        });
    },
    deletePin: {
        url: '/user/pin',
        method: 'delete',
    },
};

const TRANSID_SYSTEM_CODE = {
    fetch: {
        url: '/code',
        method: 'get',
    },
};
//endregion

//region - [차량 관리]
// 차량 관리 > 차량 등록/조회
const TRANSID_VEHICLE = {
    fetch: {
        url: '/vehicle',
        method: 'get',
    },
    get: {
        url: '/vehicle',
        method: 'get',
    },
    create: {
        url: '/vehicle',
        method: 'post',
    },
    update: {
        url: '/vehicle',
        method: 'patch',
    },
    delete: {
        url: '/vehicle',
        method: 'delete',
    },
    forceDelete: ({ ID }) =>
        request({
            url: `/vehicle/${ID}/force`,
            method: 'delete',
        }),
    fileDown: {
        url: '/vehicle/download',
        method: 'get',
        responseType: 'blob',
    },
    uploadVehicleRegister: ({ vehicleID, file }) => {
        const _formData = new FormData();
        _formData.append('file', file?.file);
        _formData.append('fileType', file?.fileType);
        return request({
            url: `/vehicle/${vehicleID}/upload`,
            method: 'post',
            data: _formData,
            headers: {
                Accept: 'multipart/form-data',
            },
        });
    },
    getVehicleBleWifi: ({ ID }) => {
        return request({
            url: `/vehicle/${ID}/bleWifi`,
            method: 'get',
        });
    },
    updateInspection: ({ ID, type }) => {
        return request({
            url: `/vehicle/${ID}/inspectionDate/${type}`,
            method: 'patch',
        })
    },
};

// 차량 일괄등록
const TRANSID_VEHICLE_UPLOAD = {
    create: {
        url: '/vehicle/upload',
        method: 'post',
        headers: {
            Accept: 'multipart/form-data',
        },
    },
};

const TRANSID_VEHICLE_UPLOAD_HISTORY = {
    fetch: {
        url: '/vehicle/uploadhistory',
        method: 'get',
    },
};

// 차량번호 중복체크
const TRANSID_VEHICLE_CHECK = {
    get: (param) => {
        const { licensePlateNumber } = param;
        return request({
            url: `/vehicle/licenseplatenumber?licensePlateNumber=${licensePlateNumber}`,
            method: 'get',
        });
    },
};

// 차량 관리 > 차량 관제
const TRANSID_VEHICLE_MONITORING = {
    fetch: {
        url: '/monitoring/vehicle',
        method: 'get',
    },
    get: {
        url: `/monitoring/vehicle`,
        method: 'get',
    },
};

// 차량 관리 > 차량 관제 > 차량 제어
const TRANSID_COMMAND = {
    fetch: {
        url: '/command',
        method: 'get',
    },
    get: {
        url: `/command`,
        method: 'get',
    },
    create: {
        url: '/command',
        method: 'post',
    },
    GetCommandStatus: ({ vehicleID }) => {
        return request({
            url: `/command/status/${vehicleID}`,
            method: 'get',
        });
    },
    CreateSmsCommand: (param) => {
        return request({
            url: `/command/sms`,
            method: 'post',
            data: param,
        });
    },
    GetBleControlHistory: ({ ...params }) => {
        // BLE 제어이력 조회
        return request({
            url: `/device/ble/command`,
            method: 'get',
            params,
        });
    },
};

// 차량 관리 > 차량 운행일지
const TRANSID_VEHICLE_DAILY_OPERATION = {
    fetch: {
        url: '/vehicle/operation',
        method: 'get',
    },
    fileDown: {
        url: `/vehicle/operation/download`,
        method: 'get',
        responseType: 'blob',
    },
    CreateOperationRdHistory: (param) => {
        return request({
            url: `/vehicle/operationrd/history`,
            method: 'post',
            data: param,
        });
    },
    GetOperationRdHistory: ({ ...params }) => {
        return request({
            url: `/vehicle/operationrd/history`,
            method: 'get',
            params,
        });
    },
};

// 차량 관리 > 차종(차량모델)
const TRANSID_VEHICLE_MODEL = {
    fetch: {
        url: '/vehiclemodel',
        method: 'get',
    },
    get: {
        url: `/vehiclemodel`,
        method: 'get',
    },
    update: {
        url: `/vehiclemodel`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    create: {
        url: '/vehiclemodel',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'post',
    },
    delete: {
        url: `/vehiclemodel`,
        method: 'delete',
    },
    CheckVehicleModelName: (param) => {
        return request({
            url: `/vehiclemodel/name?name=${param.vehicleModelName}`,
            method: 'get',
        });
    },
};

// 차량 관리 > 단말기
const TRANSID_TERMINAL = {
    fetch: {
        url: '/device',
        method: 'get',
    },
    get: {
        url: '/device',
        method: 'get',
    },
    create: {
        url: '/device',
        method: 'post',
    },
    update: {
        url: `/device`,
        method: 'patch',
    },
    delete: {
        url: '/device',
        method: 'delete',
    },
    GetDeviceStatus: ({ ...params }) => {
        return request({
            url: `/device/status`,
            method: 'get',
            params,
        });
    },
    GetPublicKeyResult: ({ deviceID }) => {
        return request({
            url: `/device/ble/sync/${deviceID}`,
            method: 'get',
        });
    },
    UpdatePublicKeyResult: ({ deviceID }) => {
        return request({
            url: `/device/ble/sync/${deviceID}`,
            method: 'patch',
        });
    },
    CreateDeviceBle: (param) => {
        return request({
            url: `/device/ble`,
            method: 'post',
            data: param,
        });
    },
    GetDeviceBle: ({ deviceID }) => {
        return request({
            url: `/device/ble/${deviceID}`,
            method: 'get',
        });
    },
    UpdateDeviceBle: ({ deviceID }) => {
        return request({
            url: `/device/ble/${deviceID}`,
            method: 'patch',
        });
    },
    DeleteDeviceBle: ({ deviceID }) => {
        return request({
            url: `/device/ble/${deviceID}`,
            method: 'delete',
        });
    },
};

// 차량 관리 > 단말기 장착 검증
const TRANSID_TERMINAL_VERIFICATION = {
    fetch: {
        url: '/device/verification',
        method: 'get',
    },
    get: {
        url: '/device/verification',
        method: 'get',
    },
    fileDown: {
        url: `/device/verification/download`,
        method: 'get',
        responseType: 'blob',
    },
}

// 차량 관리 > 충전/주유 이력
const TRANSID_REFUEL = {
    fetch: {
        url: '/refuel',
        method: 'get',
    },
    fileDown: {
        url: `/refuel/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 차량 관리 > 하이패스 이력
const TRANSID_TRANSIT = {
    fetch: {
        url: '/transit',
        method: 'get',
    },
    fileDown: {
        url: `/transit/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 차량 관리 > 사고수리 이력
const TRANSID_VEHICLE_REPAIR = {
    fetch: {
        url: '/vehicle/repair',
        method: 'get',
    },
    get: {
        url: `/vehicle/repair`,
        method: 'get',
    },
    update: {
        url: `/vehicle/repair`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    create: {
        url: '/vehicle/repair',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'post',
    },
    delete: {
        url: `/vehicle/repair`,
        method: 'delete',
    },
    changeAvailableStatus: (params)=>{
        const transData = {
            availableYn: params.availableYn,
        };
        return request({
            url: `/vehicle/repair/${params.ID}/available`,
            method: 'patch',
            data: transData,
        });
    },
    changeStatus: (params) => {
        const transData = {
            vehicleID: params.vehicleID,
            status: params.status,
        };
        return request({
            url: `/vehicle/repair/status/${params.ID}`,
            method: 'patch',
            data: transData,
        });
    },
    emailClaim: (param) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });
        return request({
            url: `/vehicle/repair/send`,
            headers: {
                Accept: 'multipart/form-data',
            },
            method: 'post',
            data: formData,
        });
    },
    getPassengerList: ({ reservationID }) => {
        return request({
            url: `/vehicle/repair/driving/passenger/${reservationID}`,
            method: 'get',
        });
    },
};
const TRANSID_VEHICLE_REPAIR_RESV = {
    get: {
        url: `/vehicle/repair/reservation`,
        method: 'get',
    },
    getDriverLicense: (params) => {
        return request({
            url: `/reservation/report/${params.reservationID}/contract`,
            method: 'get',
        });
    },
};

// 차량 관리 > 유지관리/정비 이력
const TRANSID_VEHICLE_MAINTENANCE = {
    fetch: {
        url: '/maintenance',
        method: 'get',
    },
    get: {
        url: '/maintenance',
        method: 'get',
    },
    create: {
        url: '/maintenance',
        method: 'post',
    },
    update: {
        url: '/maintenance',
        method: 'patch',
    },
    delete: {
        url: `/maintenance`,
        method: 'delete',
    },
    ListMaintenance: ({ locationID, ...params }) => {
        return request({
            url: `/maintenance/${locationID}/vehicle`,
            method: 'get',
            params,
        });
    },
    // 회원사 selectbox
    ListConBusiness: ({ ...params }) => {
        _.merge(params, {
            useYn: 'Y',
        });
        return request({
            url: `/businessPartner`,
            method: 'get',
            params,
        });
    },
    // 제어 실행
    commandMaintenance: (param) => {
        const { ID, providerID, VIN, vehicleID, commandType } = param;
        const url = `/maintenance/${ID}/command`;
        return request({
            url: url,
            method: 'post',
            data: { providerID, VIN, vehicleID, commandType },
        }).then((response) => {
            if (200 !== response.status) {
                throw response;
            }
            return response;
        });
    },
};

// 차량 관리 > 매각
const TRANSID_TRADE = {
    fetch: {
        url: '/vehicle/trade',
        method: 'get',
    },
    get: {
        url: `/vehicle/trade`,
        method: 'get',
    },
    update: {
        url: `/vehicle/trade`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    create: {
        url: '/vehicle/trade',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'post',
    },
    delete: {
        url: `/vehicle/trade`,
        method: 'delete',
    },
};

// 차량 관리 > 매각 - 차량조회
const TRANSID_TRADE_VEHICLE_LIST = {
    fetch: {
        url: '/vehicle?notVehicleStatus=SALE,SCRAP',
        method: 'get',
    },
    // 파일 삭제
    delete: {
        url: `/file`,
        method: 'delete',
    },
};

// 차량 관리 > 차량제어 이력
const TRANSID_CONTROL_HISTORY = {
    fetch: {
        url: '/controlHistory',
        method: 'get',
    },
    get: (Id, param) => {
        return request({
            url: `/controlHistory/${Id}`,
            method: 'get',
        });
    },
    create: {
        url: '/controlHistory',
        method: 'post',
    },
    delete: {
        url: `/controlHistory`,
        method: 'delete',
    },
};
const TRANSID_CONTROL_HISTORY_SERVER = {
    fetch: {
        url: '/command',
        method: 'get',
    },
    get: {
        url: `/command`,
        method: 'get',
    },
};
const TRANSID_CONTROL_HISTORY_BLE = {
    fetch: {
        url: '/device/ble/command',
        method: 'get',
    },
    get: {
        url: `/device/ble/command`,
        method: 'get',
    },
};
//endregion

//region - [위블존 관리]
// 위블존 관리 > 위블존 등록/조회
const TRANSID_LOCATION = {
    fetch: {
        url: '/location',
        method: 'get',
    },
    fileDown: {
        url: `/location/download`,
        method: 'get',
        responseType: 'blob',
    },
    get: {
        url: '/location',
        method: 'get',
    },
    create: {
        url: '/location',
        method: 'post',
    },
    update: {
        url: '/location',
        method: 'patch',
    },
    delete: {
        url: '/location',
        method: 'delete',
    },
    ListLocationBusiness: ({ locationID, ...params }) => {
        return request({
            url: `/location/${locationID}/business`,
            method: 'get',
            params,
        });
    },
    ListLocationVehicle: ({ locationID, ...params }) => {
        return request({
            url: `/location/${locationID}/vehicle`,
            method: 'get',
            params,
        });
    },
    CreateLocationVehicle: ({ locationID, ...params }) => {
        return request({
            url: `/location/${locationID}/vehicle`,
            method: 'post',
            data: params,
        });
    },
    UpdateLocationVehicle: ({ locationID, ...params }) => {
        return request({
            url: `/location/${locationID}/vehicle`,
            method: 'patch',
            data: params,
        });
    },
    DeleteLocationVehicle: ({ locationID, ...params }) => {
        return request({
            url: `/location/${locationID}/vehicle`,
            method: 'delete',
            data: params,
        });
    },
    ListLocationBusinessHistory: ({ ...params }) => {
        return request({
            url: `/location/contracthistory?contractID=${params.contractID}&businessID=${params.businessID}`,
            method: 'get',
        });
    },
};
//endregion

//region - [대여/결제 관리]
// 대여/결제 관리 > 대여 현황
const TRANSID_RESERVATION_STATUS = {
    // 일별대여현황
    getReservationStatusDaily: ({ ...params }) => {
        return request({
            url: `/reservation/status/daily`,
            method: 'get',
            params,
        });
    },
    // 월별대여현황
    getReservationStatusMonthly: ({ ...params }) => {
        return request({
            url: `/reservation/status/monthly`,
            method: 'get',
            params,
        });
    },
};

// 대여/결제 관리 > 대여 관리
const TRANSID_RESERVATION = {
    fetch: {
        url: '/reservation',
        method: 'get',
    },
    get: (param) => {
        const { ID, masking } = param;
        return request({
            url: `/reservation/${ID}?masking=${masking}`,
            method: 'get',
        });
    },
    create: {
        url: '/reservation',
        method: 'post',
    },
    update: {
        url: '/reservation',
        method: 'patch',
    },
    delete: {
        url: '/reservation',
        method: 'delete',
    },
    getImages: (reservationID) => {
        return request({
            url: `/reservation/${reservationID}/image`,
            method: 'get',
        });
    },
    cancelReservation: ({ reservationID, paramData }) => {
        return request({
            url: `/reservation/${reservationID}/cancel`,
            method: 'patch',
            data: paramData,
        });
    },
    inuseReservation: (reservationID) => {
        return request({
            url: `/reservation/${reservationID}/inuse`,
            method: 'patch',
        });
    },
    returnReservation: (reservationID) => {
        return request({
            url: `/reservation/${reservationID}/return`,
            method: 'patch',
        });
    },
    // forceReturnReservation: (reservationID) => {
    //     return request({
    //         url: `/reservation/${reservationID}/forcereturn`,
    //         method: 'patch',
    //     });
    // },
    forceReturnReservation: ({ reservationID, paramData }) => {
        return request({
        url: `/reservation/${reservationID}/forcereturn`,
        method: 'patch',
        data: paramData,
        });
    },
    extendReservation: ({ reservationID, paramData }) => {
        return request({
            url: `/reservation/${reservationID}/extend`,
            method: 'patch',
            data: paramData,
        });
    },
    changeVehicle: (data) => {
        if (!data?.reservaionID) return;

        return request({
            url: `/reservation/${data?.reservaionID}/vehicle`,
            method: 'patch',
            data: data,
        });
    },
    fileDown: {
        url: '/reservation/down',
        method: 'get',
        responseType: 'blob',
    },
};
const TRANSID_RESERVATION_STATS = {
    fetch: {
        url: '/reservation/stat',
        method: 'get',
    },
};

// 대여가능차량조회
const TRANSID_AVAILABLE_VEHICLE = {
    fetch: {
        // timebase
        url: '/location/poi/timebase',
        method: 'get',
    },
    getPassVehicle: (data) => {
        return request({
            url: '/location/poi/pass',
            method: 'post',
            data: data,
        });
    },
    getSpecialVehicle: (data) => {
        return request({
            url: '/location/poi/special',
            method: 'post',
            data: data,
        });
    },
    getOnedayVehicle: (data) => {
        return request({
            url: '/location/poi/oneday',
            method: 'post',
            data: data,
        });
    },
    getSpaceVehicle: (param) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        const url = `/location/poi/space?${paramAry.join('&')}`;
        return request({
            url: url,
            method: 'get',
        });
    },
    getMonthlyVehicle: (param) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        const url = `/location/poi/monthly?${paramAry.join('&')}`;
        return request({
            url: url,
            method: 'get',
        });
    },
    getCampingVehicle: (param) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        const url = `/location/poi/camping?${paramAry.join('&')}`;
        return request({
            url: url,
            method: 'get',
        });
    },
    getMaxReturnTime: ({ reservationID }) => {
        return request({
            url: `/location/${reservationID}/maxtime`,
            method: 'get',
        });
    },
    getCampingMaxReturnTime: ({ reservationID }) => {
        return request({
            url: `/location/${reservationID}/campingmaxtime`,
            method: 'get',
        });
    },
};

const TRANSID_AVAILABLE_CHARGETYPE = {
    get: (param) => {
        const { vehicleID, chargeTypeCd } = param;
        return request({
            url: `/vehicle/scheduleChk/${vehicleID}?chargeTypeCd=${chargeTypeCd}`,
            method: 'get',
        });
    },
};

// 대여연장 - 휴일조회
const TRANSID_HOLIDAY = {
    fetch: {
        url: '/holiday',
        method: 'get',
    },
};

// 대여연장 - 이어타기
const TRANSID_POI_RELATION = {
    fetch: {
        url: '/location/poi/relation',
        method: 'get',
    },
};

const TRANSID_POI_PERSONAL_RELATION = {
    fetch: {
        url: '/location/poi/personalrelation',
        method: 'get',
    },
};

// 부가상품 대여요금 계산
const TRANSID_ADDGOODS_POI = {
    fetch: {
        url: `/addgoodsCharge/poi`,
        method: 'get',
    }
};

// 결제요금조회
const TRANSID_CHARGE_CAL = {
    getChargeCal: ({ chargeID, param }) => {
        return request({
            url: `/charge/${chargeID}/cal?insuranceGroupID=${param?.insuranceGroupID}&reservationStartTime=${param?.reservationStartTime}&reservationEndTime=${param?.reservationEndTime}`,
            method: 'get',
            // ...param,
        });
    },
};

// 적용가능쿠폰 조회, 쿠폰적용금액계산
const TRANSID_AVAILABLE_COUPON = {
    fetch: {
        url: '/coupon/user/book',
        method: 'get',
    },
    estimateCouponDiscount: ({ couponUserID, param }) => {
        // const formData = new FormData();
        // Object.keys(param).forEach((key) => {
        //     if (Array.isArray(param[key])) {
        //         param[key].forEach((item) => {
        //             formData.append(key, item);
        //         });
        //     } else formData.append(key, param[key]);
        // });
        return request({
            url: `/coupon/${couponUserID}/estimated`,
            method: 'get',
            params: param,
        });
    },
};

const TRANSID_ACTUALISSUED_COUPON = {
    fetch: {
        url: '/coupon/customer/issued',
        method: 'get',
    },
};

const TRANSID_ACTUALUSER_COUPON = {
    fetch: {
        url: '/coupon/customer/used',
        method: 'get',
    },
};

// 미수금 회원 조회
const TRANSID_CLAIM = {
    get: {
        url: '/payment/unpaid/alim',
        method: 'get',
    },
};

// 대여/결제관리 > 대여내역
const TRANSID_RESERVATION_PRODUCT = {
    fetch: {
        url: '/reservation/product',
        method: 'get',
    },
    get: ({ ID, masking }) => {
        return request({
            url: `/reservation/product/${ID}?masking=${masking}`,
            method: 'get',
        });
    },
    fileDown: {
        url: '/reservation/product/down',
        method: 'get',
        responseType: 'blob',
    },
};

const TRANSID_RESERVATION_PRODUCT_STATS = {
    fetch: {
        url: '/reservation/product/stat',
        method: 'get',
    },
};

const TRANSID_CARD = {
    fetch: {
        url: `/card`,
        method: 'get',
    },
    // 카드
    getCardListCardCds1: (param) => {
        return request({
            url: `/card`,
            method: 'get',
            params: param,
        });
    },
};

// 구독 내역
const TRANSID_SUBSCRIBE = {
    fetch: {
        url: `/subscribe`,
        method: 'get',
    },
    get: {
        url: `/subscribe`,
        method: 'get',
    },
    create: {
        url: '/subscribe',
        method: 'post',
    },
    update: {
        url: `/subscribe`,
        method: 'patch',
    },
    delete: {
        url: `/subscribe`,
        method: 'delete',
    },
    CancelSubscribe: ({ subscribeID }) => {
        return request({
            url: `/subscribe/${subscribeID}/cancel`,
            method: 'patch',
        });
    },
    CancelSubscribeCheck: ({ subscribeID }) => {
        return request({
            url: `/subscribe/${subscribeID.subscribeID}/cancel`,
            method: 'get',
        });
    },
    fileDown: {
        url: '/subscribe/down',
        method: 'get',
        responseType: 'blob',
    },
    getSubscribeStat: ({ ...params }) => {
        return request({
            url: `/subscribe/stat`,
            method: 'get',
            params,
        });
    },
};

// 결제 내역
const TRANSID_PAYMENT = {
    fetch: {
        url: '/payment?deleteUseYn=Y',
        method: 'get',
    },
    get: {
        url: '/payment',
        method: 'get',
    },
    fileDown: {
        url: `/payment/down`,
        method: 'get',
        responseType: 'blob',
    },
    getPaymentStat: ({ ...params }) => {
        return request({
            url: `/payment/stat`,
            method: 'get',
            params,
        });
    },
    CancelPayment: ({ ID }) => {
        return request({
            url: `/payment/${ID}/cancel`,
            method: 'patch',
        });
    },
    PartCancelPayment: ({ ID, ...params }) => {
        return request({
            url: `/payment/${ID}/partcancel`,
            method: 'patch',
            data: params,
        });
    },
    reservationUnpaidCheck: ({ userID }) => {
        return request({
            url: `/payment/unpaid/${userID}`,
            method: 'get',
        });
    },
};

// 결제 카드
const TRANSID_PAYMENT_CARD = {
    fetch: {
        url: '/payment/business/card',
        method: 'get',
    },
    get: {
        url: '/payment/business/card',
        method: 'get',
    },
    create: {
        url: '/payment/business/card',
        method: 'post',
    },
    update: {
        url: '/payment/business/card',
        method: 'patch',
    },
    fileDown: {
        url: '/payment/business/card/download',
        method: 'get',
        responseType: 'blob',
    },
    fetchCardList: (param) => {
        return request({
            url: `/payment/business/card`,
            method: 'get',
            params: param,
        });
    },
};

// 하이패스 카드
const TRANSID_HIPASS = {
    fetch: {
        url: '/hipass/card',
        method: 'get',
    },
    get: {
        url: `/hipass/card`,
        method: 'get',
    },
    create: {
        url: '/hipass/card',
        method: 'post',
    },
    update: {
        url: `/hipass/card`,
        method: 'patch',
    },
    delete: {
        url: `/hipass/card`,
        method: 'delete',
    },
    checkCardNum: (param) => {
        return request({
            url: `/hipass/card/number?cardNum=${param.cardNum}`,
            method: 'get',
        });
    },
    fileDown: {
        url: `/hipass/card/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 충전제휴 카드
const TRANSID_CHARGECARD = {
    fetch: {
        url: '/oil/card',
        method: 'get',
    },
    get: {
        url: `/oil/card`,
        method: 'get',
    },
    create: {
        url: '/oil/card',
        method: 'post',
    },
    update: {
        url: `/oil/card`,
        method: 'patch',
    },
    checkCardNum: (param) => {
        return request({
            url: `/oil/card/number?cardNum=${param.cardNum}`,
            method: 'get',
        });
    },
    fileDown: {
        url: `/oil/card/download`,
        method: 'get',
        responseType: 'blob',
    },
};
//endregion

//region - [회원사 관리]
const TRANSID_PLAIN_BUSINESS = {
    fetch: (params) => {
        _.merge(params, { businessTypeCd: 'CORPORATION' });
        return request({
            url: `/plainBusiness`,
            method: 'get',
            params,
        });
    },
};

// 회원사/계약
const TRANSID_BUSINESS = {
    fetch: {
        url: `/business`,
        method: 'get',
    },
    get: {
        url: `/business/businessInfo`,
        method: 'get',
    },
    create: {
        url: '/business',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'post',
    },
    update: {
        url: `/business`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    delete: {
        url: `/business`,
        method: 'delete',
    },
    getSuperManager: (param) => {
        return request({
            url: `/business/superManager/${param.ID}`,
            method: 'get',
        });
    },
    updateBusinessInvite: (ID, param) => {
        return request({
            url: `/business/businessInvite/${ID}`,
            method: 'patch',
            params: param,
        });
    },

    getBusinessImage: (param) => {
        return request({
            url: `/business/image/${param.ID}`,
            method: 'get',
        });
    },

    getContractByBusinessID: ({ ...params }) => {
        return request({
            url: `/business/contract/current`,
            method: 'get',
            params,
        });
    },

    deleteBusinessImage: (param) => {
        return request({
            url: `/business/image/${param.businessID}`,
            method: 'delete',
        });
    },

    fileDown: {
        url: `/business/download`,
        method: 'get',
        responseType: 'blob',
    },
};

const TRANSID_BUSINESS_ADMIN_INVITE = {
    update: {
        url: `/business/businessInvite`,
        method: 'patch',
    },
};

const TRANSID_BUSINESS_CONTRACT = {
    create: {
        url: '/business',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'post',
    },

    getBusinessContract: (param) => {
        return request({
            url: `/business/contract?businessID=${param.businessID}&contractID=${param.contractID}`,
            method: 'get',
        });
    },

    update: {
        url: `/business/contract`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },

    getBusinessContractHistory: (param) => {
        return request({
            url: `/business/contractHistory/${param.ID}`,
            method: 'get',
        });
    },
};

const TRANSID_CONTRACT = {
    get: {
        url: '/contract',
        method: 'get',
    },

    update: (id, param) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });

        return request({
            url: `/contract/customerService/${id}`,
            // headers: {
            //     Accept: 'multipart/form-data',
            //     'Content-Type': 'multipart/form-data',
            // },
            method: 'post',
            data: formData,
        });
    },
};

// 기업 상담이력
const TRANSID_CUSTOMER_SURVICE = {
    fetch: {
        url: '/customerService',
        method: 'get',
    },
    get: {
        url: '/customerService',
        method: 'get',
    },
    create: {
        url: '/customerService',
        method: 'post',
    },
    update: {
        url: '/customerService',
        method: 'patch',
    },
    fileDown: {
        url: '/customerService/download',
        method: 'get',
        responseType: 'blob',
    },
};

// 기업 상담이력 히스토리
const TRANSID_CUSTOMER_SERVICE_HISTORY = {
    fetch: (param) => {
        return request({
            url: `/customerService/history/${param.refer}`,
            method: 'get',
        });
    },
};
// 계약 - 기업 상담등록
const TRANSID_CUSTOMER_SURVICE_CONTRACT = {
    create: {
        url: '/contract/customerService',
        method: 'post',
    },
};
//endregion

//region - [회원/부서 관리]
// 회원
const TRANSID_CUSTOMER_USER = {
    fetch: {
        url: '/customerUser',
        method: 'get',
    },
    listSimple: ({ ...params })  => {
        if (params?.current) delete params.current;
        if (params?.totalRecord)  delete params.totalRecord;
        if (params?.scale) {
            params.limit = params.scale;
            delete params.scale;
        }

        return request({
            url: `/customerUser/simple`,
            method: 'get',
            params,
        });
    },
    get: {
        url: '/customerUser',
        method: 'get',
    },
    update: {
        url: `/customerUser`,
        method: 'patch',
    },
    fileDown: {
        url: `/customerUser/download`,
        method: 'get',
        responseType: 'blob',
    },
    notificationFileDown: {
        url: `/customerUser/download/notification`,
        method: 'get',
        responseType: 'blob',
    },
    updateLicenseStatus: ({ ID, data }) => {
        return request({
            url: `/customerUser/${ID}/licenseStatus`,
            method: 'patch',
            data: data,
        });
    },
    updatePassportStatus: ({ ID, data }) => {
        return request({
            url: `/customerUser/${ID}/passportStatus`,
            method: 'patch',
            data: data,
        });
    },
    deleteDriverLicense: ({ ID }) => {
        return request({
            url: `/customerUser/${ID}/driverLicense`,
            method: 'delete',
        });
    },
    getCouponList: ({ currFilters }) => {
        const { ID, ...rest } = currFilters;
        return request({
            url: `/customerUser/coupon/${ID}`,
            method: 'get',
            params: rest,
        });
    },
    getRecommendList: ({ currFilters }) => {
        const { ID, ...rest } = currFilters;
        return request({
            url: `/customerUser/recommend/${ID}`,
            method: 'get',
            params: rest,
        });
    },
};

// 회원 > 1:1 문자발송
const TRANSID_CUSTOMER_USER_MESSENGER = {
    create: {
        url: '/voc/message',
        method: 'post',
    },
};

// 탈퇴회원
const TRANSID_CUSTOMER_WITHDRAW_USER = {
    fetch: {
        url: '/withdrawalUser',
        method: 'get',
    },
    get: {
        url: '/withdrawalUser',
        method: 'get',
    },
    fileDown: {
        url: `/withdrawalUser/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 임직원 조회
const TRANSID_BUSINESS_MEMBER = {
    fetch: {
        url: `/customerEmployee`,
        method: 'get',
    },
    get: {
        url: `/customerEmployee/detail`,
        method: 'get',
    },
    update: {
        url: `/customerEmployee`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    delete: {
        url: `/customerEmployee`,
        method: 'delete',
    },
    fileDown: {
        url: `/customerEmployee/download`,
        method: 'get',
        responseType: 'blob',
    },
    department: {
        url: `/customerEmployee/department`,
        method: 'get',
    },
};

// 임직원 등록
const TRANSID_BUSINESS_INVITE = {
    fetch: {
        url: '/businessInvite',
        method: 'get',
    },
    delete: (params) => {
        return request({
            url: `/businessInvite/${params.businessID}`,
            method: 'delete',
            data: params,
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    create: {
        url: '/businessInvite',
        method: 'post',
    },
    fileDown: {
        url: `/businessInvite/download`,
        method: 'get',
        responseType: 'blob',
    },

    getSampleFileInfo: () => {
        const url = '/businessInvite/sampleDownload';
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },

    upload: (param) => {
        const formData = new FormData();
        param.fileType = 'file';
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });

        return request({
            url: '/businessInvite/upload',
            headers: {
                Accept: 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
            },
            method: 'post',
            data: formData,
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    sendEmailAll: (filter) => {
        const url = `/businessInvite/sendEmailAll`;
        return request({
            url: url,
            method: 'get',
            params: filter,
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    sendEmailSelect: ({ businessID, employeeEmails }) => {
        const url = `/businessInvite/sendEmailSelect?businessID=${businessID}&employeeEmails=${employeeEmails.join(
            '&employeeEmails=',
        )}`;
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    getSampleExcelDown: ({ key }) => {
        const url = `/businessInvite/sample/download?businessID=${key}`;
        return request({
            url: url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
};
// 임직원 등록 업로드 이력
const TRANSID_BUSINESS_INVITE_HISTORY = {
    fetch: {
        url: '/uploadHistory',
        method: 'get',
    },
    getHistoryExcelDown: ({ link }) => {
        const url = link;
        return request({
            url: url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
};

// 부서
const TRANSID_DEPARTMENT = {
    fetch: {
        url: '/department',
        method: 'get',
    },
    get: {
        url: `/department`,
        method: 'get',
    },
    update: {
        url: `/department`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    create: {
        url: '/department',
        method: 'post',
    },
    delete: {
        url: '/department',
        method: 'delete',
    },
    deleteManager: (ID) => {
        return request({
            url: `/departmentManager/${ID}`,
            method: 'delete',
        });
    },
    upload: (param) => {
        const formData = new FormData();
        param.fileType = 'file';
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });

        return request({
            url: '/department/upload',
            headers: {
                Accept: 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
            },
            method: 'post',
            data: formData,
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },

    getSampleFileInfo: () => {
        const url = '/department/sampleDownload';
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },

    getDepartmentSampleExcelDown: ({ ID }) => {
        const url = `/department/sample/download?businessID=${ID}`;
        return request({
            url: url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    fileDown: {
        url: '/department/download',
        method: 'get',
        responseType: 'blob',
    },
};

// 부서 담당자 등록
const TRANSID_EMPLOYEE_IN_DEPARTMENT = {
    fetch: {
        url: '/department/employee',
        method: 'get',
    },
};

// 회원사 권한
const TRANSID_BUSINESS_OPERATOR = {
    fetch: {
        url: '/admin',
        method: 'get',
    },
    get: {
        url: `/admin`,
        method: 'get',
    },
    update: {
        url: `/admin`,
        method: 'patch',
    },
    delete: {
        url: `/admin`,
        method: 'delete',
    },
    fileDown: {
        url: `/admin/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 운전면허 검증이력
const TRANSID_LICENSE_CHECK_LIST = {
    fetch: {
        url: '/license/check',
        method: 'get',
    },
    get: {
        url: `/license/check`,
        method: 'get',
    },
    fileDown: {
        url: `/license/check/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 페널티
const TRANSID_PENALTY = {
    fetch: {
        url: `/penalty/user`,
        method: 'get',
    },
    get: {
        url: `/penalty/user`,
        method: 'get',
    },
    create: {
        url: `/penalty/user`,
        method: 'post',
    },
    update: {
        url: `/penalty/user`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    fileDown: {
        url: '/penalty/user/download',
        method: 'get',
        responseType: 'blob',
    },
    getPenaltyPolicy: (param) => {
        return request({
            url: `/penalty?penaltyID=${param.penaltyID}&noPaging=${param.noPaging}`,
            method: 'get',
        });
    },
    claim: ({ ID }) => {
        return request({
            url: `/penalty/user/${ID}/claim`,
            method: 'post',
        });
    },
    reduceFee: ({ ID }) => {
        return request({
            url: `/penalty/user/${ID}/reduceFee`,
            method: 'post',
        });
    },
    reducePoint: ({ ID }) => {
        return request({
            url: `/penalty/user/${ID}/reducePoint`,
            method: 'post',
        });
    },
    exemption: ({ ID }) => {
        return request({
            url: `/penalty/user/${ID}/exemption`,
            method: 'post',
        });
    },
    payment: (params) => {
        const cardID = { cardID: params.cardID };
        return request({
            url: `/penalty/user/${params.ID}/payment`,
            method: 'post',
            // params: params,
            data: cardID,
        });
    },
    complete: ({ ID }) => {
        return request({
            url: `/penalty/user/${ID}/complete`,
            method: 'post',
        });
    },
};

// 페널티 정책
const TRANSID_PENALTY_POLICY = {
    fetch: {
        url: `/penalty`,
        method: 'get',
    },
    get: {
        url: `/penalty`,
        method: 'get',
    },
    create: {
        url: `/penalty`,
        method: 'post',
    },
    fileDown: {
        url: '/penalty/download',
        method: 'get',
        responseType: 'blob',
    },
    update: {
        url: `/penalty`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    delete: {
        url: `/penalty`,
        method: 'delete',
    },
};

// 운전면허 조회
const TRANSID_DRIVER_LICENSE_INFO = {
    fetch: {
        url: '/customerUser/driverLicense',
        method: 'get',
    },
    fileDown: {
        url: `/customerUser/driverLicense/download`,
        method: 'get',
        responseType: 'blob',
    },
};
//endregion

//region - [매출/정산 관리]
// 매출/정산 관리 > 매출
const TRANSID_SALES = {
    fetch: {
        url: `/sales`,
        method: 'get',
    },
    get: {
        url: `/sales`,
        method: 'get',
    },
    create: {
        url: '/sales',
        method: 'post',
    },
    update: {
        url: `/sales`,
        method: 'patch',
    },
    delete: {
        url: `/sales`,
        method: 'delete',
    },
    fileDown: {
        url: '/sales/download',
        method: 'get',
        responseType: 'blob',
    },
    accountFileDown: {
        url: `/sales/accounting/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 매출/정산 관리 > 쿠폰 매출
const TRANSID_COUPON_SALES = {
    fetch: {
        url: '/couponSales',
        method: 'get',
    },
    fileDown: {
        url: `/couponSales/download`,
        method: 'get',
        responseType: 'blob',
    },
    get: {
        url: '/couponSales',
        method: 'get',
    },
};

// 매출/정산 관리 > 정산
const TRANSID_CALCULATION = {
    fetch: {
        url: '/calculation',
        method: 'get',
    },
    fileDown: {
        url: `/calculation/download`,
        method: 'get',
        responseType: 'blob',
    },
    get: {
        url: '/calculation',
        method: 'get',
    },
    create: {
        url: '/calculation',
        method: 'post',
    },
    update: {
        url: '/calculation',
        method: 'patch',
    },
    delete: {
        url: '/calculation',
        method: 'delete',
    },
    GetClaim: (params) => {
        // 별도정산 대상 청구 조회
        return request({
            url: `/calculation/claim`,
            method: 'get',
            params,
        });
    },
    GetClaimExcelDown: ({ ID }) => {
        // 별도정산 대상 목록 엑셀 다운로드
        return request({
            url: `/calculation/claim/${ID}/download`,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            return response;
        });
    },
    ActionClaim: ({ ID }) => {
        // 청구하기
        return request({
            url: `/calculation/${ID}/claim`,
            method: 'put',
            // method: 'patch',
        });
    },
    ActionCalculationCard: ({ ID, ...params }) => {
        // 정산완료 (카드결제)
        return request({
            url: `/calculation/${ID}/card`,
            method: 'put',
            data: params,
        });
    },
    ActionCalculationCash: ({ ID, ...params }) => {
        // 정산완료 (계좌이체)
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
                params[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, params[key]);
        });
        return request({
            url: `/calculation/${ID}/cash`,
            headers: {
                Accept: 'multipart/form-data',
            },
            method: 'put',
            data: formData,
        });
    },
};

// 쿠폰
const TRANSID_COUPON = {
    fetch: {
        url: `/coupon`,
        method: 'get',
    },
    get: {
        url: `/coupon`,
        method: 'get',
    },
    create: {
        url: '/coupon',
        method: 'post',
    },
    update: {
        url: `/coupon`,
        method: 'patch',
    },
    delete: {
        url: `/coupon`,
        method: 'delete',
    },
    createLedgerUser: ({ ID, data }) => {
        const url = `/coupon/${ID}/issue/history/user`;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                data[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, data[key]);
        });
        return request({
            url: url,
            method: 'post',
            data: formData,
            headers: {
                Accept: 'multipart/form-data',
            },
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },

    createLedgerRandom: ({ ID, data }) => {
        const url = `/coupon/${ID}/issue/history/random`;
        return request({
            url: url,
            method: 'post',
            data: data,
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    createLedgerAffiliateUrl: ({ data }) => {
        return request({
            url: '/coupon/alliance',
            method: 'post',
            data: data,
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    updateLedgerAffiliateUrl: ({ ID, data }) => {
        return request({
            url: `/coupon/alliance/${ID}`,
            method: 'patch',
            data: data,
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    getLedgerAffiliateUrl: ({ ID }) => {
        return request({
            url: `/coupon/alliance/${ID}`,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    getUserLedgerList: ({ ID, historyID }) => {
        const url = `/coupon/${ID}/issue/history/${historyID}/user`;
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    getUserLedgerExcelDown: ({ ID, historyID }) => {
        const url = `/coupon/${ID}/issue/history/${historyID}/user/download`;
        return request({
            url: url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            return response;
        });
    },
    getRandomLedgerExcelDown: ({ ID, historyID }) => {
        const url = `/coupon/${ID}/issue/history/${historyID}/random/download`;
        return request({
            url: url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            return response;
        });
    },
    fileDown: (param) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        const url = `/coupon/download?${paramAry.join('&')}`;
        return request({
            url: url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            return response;
        });
    },
};

// 미수금
const TRANSID_RECEIVBLE = {
    fetch: {
        url: `/unpaid`,
        method: 'get',
    },
    get: {
        url: `/unpaid`,
        method: 'get',
    },
    update: {
        url: `/unpaid`,
        method: 'patch',
    },
    create: {
        url: '/unpaid',
        method: 'post',
    },
    delete: {
        url: `/unpaid`,
        method: 'delete',
    },
    fileDown: {
        url: `/unpaid/download`,
        method: 'get',
        responseType: 'blob',
    },
    unpaidAlim: ({ userID }) => {
        const url = `/payment/unpaid/alim/${userID}`;
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    unpaidMonthlyBusiness: ({ paymentID, param }) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });

        const url = `/unpaid/${paymentID}/business?${paramAry.join('&')}`;
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    unpaidMonthlyPersonal: ({ paymentID, param }) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });

        const url = `/unpaid/${paymentID}/personal?${paramAry.join('&')}`;
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    unpaidMonthlyBusinessExcelDown: ({ paymentID, param }) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });

        const url = `/unpaid/${paymentID}/business/download?${paramAry.join(
            '&',
        )}`;
        return request({
            url: url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            return response;
        });
    },
    unpaidMonthlyPersonalExcelDown: ({ paymentID, param }) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });

        const url = `/unpaid/${paymentID}/personal/download?${paramAry.join(
            '&',
        )}`;
        return request({
            url: url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            return response;
        });
    },
    payBuillCash: ({ paymentID, param }) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });
        return request({
            url: `/unpaid/${paymentID}/cash`,
            method: 'put',
            data: formData,
            headers: {
                Accept: 'multipart/form-data',
            },
        });
    },
    payBuillCard: ({ paymentID, param }) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });
        return request({
            url: `/unpaid/${paymentID}/card`,
            data: formData,
            method: 'put',
        });
    },
    exemptionRemainAmt: ({ paymentID, param }) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });
        return request({
            url: `/unpaid/${paymentID}/exemption`,
            method: 'patch',
            data: formData,
        });
    },
};

// 과태료
const TRANSID_VIOLATION_MGMT = {
    fetch: {
        url: '/violation',
        method: 'get',
    },
    get: {
        url: `/violation`,
        method: 'get',
    },
    update: {
        url: `/violation`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    create: {
        url: '/violation',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'post',
    },
    delete: {
        url: `/violation`,
        method: 'delete',
    },
    LoadReservationInfo: (param) => {
        return request({
            url: `/violation/reservation?licensePlateNumber=${param.licensePlateNumber}&violationTime=${param.violationTime}`,
            method: 'get',
        });
    },
    fileDown: {
        url: `/violation/download`,
        method: 'get',
        responseType: 'blob',
    },
    transferSend: (param) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });
        return request({
            url: `/violation/transfer/send`,
            headers: {
                Accept: 'multipart/form-data',
            },
            method: 'post',
            data: formData,
        });
    },
};
// endregion

//region - [상품 관리]
// 요금제
const TRANSID_CHARGE = {
    fetch: {
        url: `/charge`,
        method: 'get',
    },
    get: {
        url: `/charge`,
        method: 'get',
    },
    update: {
        url: `/charge`,
        method: 'patch',
    },
    create: {
        url: `/charge`,
        method: 'post',
    },
    delete: {
        url: `/charge`,
        method: 'delete',
    },
    fileDown: {
        url: `/charge/download`,
        method: 'get',
        responseType: 'blob',
    },
    getTimebaseProduct: (param) => {
        return request({
            url: `/charge?chargeTypeCd=${param.chargeTypeCd}&noPaging=${param.noPaging}`,
            method: 'get',
        });
    },
};

// 보험료
const TRANSID_INSURANCEGROUP = {
    fetch: {
        url: `/insurancegroup`,
        method: 'get',
    },
    get: {
        url: `/insurancegroup`,
        method: 'get',
    },
    create: {
        url: `/insurancegroup`,
        method: 'post',
    },
    update: {
        url: `/insurancegroup`,
        method: 'patch',
    },
    delete: {
        url: `/insurancegroup`,
        method: 'delete',
    },
    fileDown: {
        url: `/insurancegroup/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 부가상품
const TRANSID_ADDITION_GOODS = {
    fetch: {
        url: `/addgoods`,
        method: 'get',
    },
    get: {
        url: `/addgoods`,
        method: 'get',
    },
    create: {
        url: `/addgoods`,
        method: 'post',
    },
    update: {
        url: `/addgoods`,
        method: 'patch',
    },
    delete: {
        url: `/addgoods`,
        method: 'delete',
    },
    fileDown: {
        url: `/addgoods/download`,
        method: 'get',
        responseType: 'blob',
    },
};
// endregion

//region - [콘텐츠 관리]
// 공지/이벤트
const TRANSID_NOTICE = {
    fetch: {
        url: '/notice',
        method: 'get',
    },
    get: {
        url: `/notice`,
        method: 'get',
    },
    update: (param) => {
        const { id, ...data } = param || {};
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                data[key].forEach((item) => {
                    if (key === 'noticeBusiness') {
                        // noticeBusiness 배열 예외 처리
                        formData.append(key, JSON.stringify(item));
                    } else formData.append(key, item);
                });
            } else formData.append(key, data[key]);
        });

        return request({
            url: `/notice/${id}`,
            headers: {
                Accept: 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
            },
            method: 'patch',
            data: formData,
        });
    },
    // update: {
    //     url: `/notice`,
    //     headers: {
    //         Accept: 'multipart/form-data',
    //     },
    //     method: 'patch',
    // },
    create: (param) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    if (key === 'noticeBusiness') {
                        // noticeBusiness 배열 예외 처리
                        formData.append(key, JSON.stringify(item));
                    } else formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });

        return request({
            url: `/notice`,
            headers: {
                Accept: 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
            },
            method: 'post',
            data: formData,
        });
    },
    delete: {
        url: `/notice`,
        method: 'delete',
    },
    listContents: (param) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        const url = `/notice/listContents?${paramAry.join('&')}`;
        return request({
            url: url,
            method: 'get',
        });
    },
    fileDown: {
        url: `/notice/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 공지/이벤트 회원사 정보
const TRANSID_NOTICE_COMPANY = {
    fetch: (param) => {
        return request({
            url: `/notice/${param.refer}/history`,
            method: 'get',
        });
    },
};

// 위블존 이용안내
const TRANSID_GUIDE_LOCATION = {
    fetch: {
        url: `/locationInfo`,
        method: 'get',
    },
    get: {
        url: `/locationInfo`,
        method: 'get',
    },
    create: {
        url: `/locationInfo`,
        method: 'post',
    },
    delete: {
        url: `/locationInfo`,
        method: 'delete',
    },
    update: {
        url: `/locationInfo`,
        method: 'patch',
    },
};

// 앱 콘텐츠 관리
const TRANSID_GUIDE_CONTENTS = {
    fetch: {
        url: `/contentsInfo`,
        method: 'get',
    },
    get: {
        url: `/contentsInfo`,
        method: 'get',
    },
    create: {
        url: `/contentsInfo`,
        method: 'post',
    },

    update: {
        url: `/contentsInfo`,
        method: 'patch',
    },
    delete: {
        url: `/contentsInfo`,
        method: 'delete',
    },
    getContentInfo: (id) => {
        return request({
            url: `/contentsInfo/${id}`,
            method: 'get',
        });
    },
};

// 차종별 이용안내
const TRANSID_MODEL_INFO = {
    fetch: {
        url: '/modelInfo',
        method: 'get',
    },
    get: {
        url: '/modelInfo',
        method: 'get',
    },
    update: {
        url: '/modelInfo',
        method: 'patch',
    },
    create: {
        url: '/modelInfo',
        method: 'post',
    },
    delete: {
        url: '/modelInfo',
        method: 'delete',
    },
};

// 배너/스플래시/팝업
const TRANSID_BANNER_MGMT = {
    fetch: {
        url: '/splash',
        method: 'get',
    },
    get: {
        url: `/splash`,
        method: 'get',
    },
    update: (param) => {
        const { id, ...data } = param || {};
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                data[key].forEach((item) => {
                    if (key === 'splashBusiness') {
                        // noticeBusiness 배열 예외 처리
                        formData.append(key, JSON.stringify(item));
                    } else formData.append(key, item);
                });
            } else formData.append(key, data[key]);
        });

        return request({
            url: `/splash/${id}`,
            headers: {
                Accept: 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
            },
            method: 'patch',
            data: formData,
        });
    },
    create: (param) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    if (key === 'splashBusiness') {
                        // noticeBusiness 배열 예외 처리
                        formData.append(key, JSON.stringify(item));
                    } else formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });

        return request({
            url: `/splash`,
            headers: {
                Accept: 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
            },
            method: 'post',
            data: formData,
        });
    },
    delete: {
        url: `/splash`,
        method: 'delete',
    },
    listContents: (param) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        const url = `/splash/listContents?${paramAry.join('&')}`;
        return request({
            url: url,
            method: 'get',
        });
    },
    fileDown: {
        url: `/splash/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 알림 템플릿
const TRANSID_MSG_TEMPLATE = {
    fetch: {
        url: '/template',
        method: 'get',
    },
    get: {
        url: `/template`,
        method: 'get',
    },
    update: {
        url: `/template`,
        method: 'patch',
    },
    create: {
        url: '/template',
        method: 'post',
    },
    delete: {
        url: `/template`,
        method: 'delete',
    },
    fileDown: {
        url: `/template/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 알림발송이력
const TRANSID_HISTORY_MSG_TEMPLATE = {
    fetch: {
        url: '/notification/history',
        method: 'get',
    },
    get: {
        url: `/notification/history`,
        method: 'get',
    },
    fileDown: {
        url: '/notification/history/download',
        method: 'get',
        responseType: 'blob',
    },
};

// 수동메시지 관리
const TRANSID_PASSIVE_MESSAGE = {
    fetch: {
        url: '/notification/control',
        method: 'get',
    },
    get: {
        url: `/notification/control`,
        method: 'get',
    },
    create: {
        url: '/notification/control',
        method: 'post',
        headers: {
            Accept: 'multipart/form-data',
        },
    },
    sendCancel: ({ ID }) => {
        return request({
            url: `/notification/control/${ID}`,
            method: 'patch',
        });
    },
    delete: {
        url: `/notification/control`,
        method: 'delete',
    },
    excelDown: ({ ID }) => {
        return request({
            url: `/notification/control/${ID}/receiver/download`,
            method: 'get',
            responseType: 'blob',
            // data: { ID },
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    getSampleFileID: {
        url: `/notification/control/sampleDownload`,
        method: 'get',
    },
};

// 약관관리
const TRANSID_TERMS = {
    fetch: {
        url: '/terms/mst',
        method: 'get',
    },
    get: {
        url: `/terms/mst`,
        method: 'get',
    },
    update: {
        url: `/terms/mst`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    create: {
        url: '/terms/mst',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'post',
    },
    delete: {
        url: `/terms/mst`,
        method: 'delete',
    },
    fileDown: {
        url: `/terms/download`,
        method: 'get',
        responseType: 'blob',
    },
    getGroupList: {
        url: `/user/terms/group`,
        method: 'get',
    },
    getGroup: {
        url: `/user/terms/group/last`,
        method: 'get',
    },
};

// 약관관리 히스토리
const TRANSID_TERMS_HISTORY = {
    fetch: ({ param }) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        return request({
            url: `/terms/mst?${paramAry.join('&')}`,
            method: 'get',
        });
    },
};
// endregion

//region - [운영 관리]
// 버전
const TRANSID_APP_VERSION = {
    fetch: {
        url: '/appVersion',
        method: 'get',
    },
    get: {
        url: `/appVersion`,
        method: 'get',
    },
    create: {
        url: '/appVersion',
        method: 'post',
    },
    update: {
        url: '/appVersion',
        method: 'patch',
    },
    delete: {
        url: `/appVersion`,
        method: 'delete',
    },
    fileDown: {
        url: `/appVersion/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 어드민
const TRANSID_OPERATOR = {
    fetch: {
        url: '/operator',
        method: 'get',
    },
    get: {
        url: `/operator`,
        method: 'get',
    },
    update: {
        url: `/operator`,
        method: 'patch',
    },
    delete: {
        url: `/operator`,
        method: 'delete',
    },
    fileDown: {
        url: `/operator/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 위탁사
const TRANSID_BUSINESS_PARTNER = {
    fetch: {
        url: '/businessPartner',
        method: 'get',
    },
    get: {
        url: '/businessPartner',
        method: 'get',
    },
    create: {
        url: `/businessPartner`,
        method: 'post',
        headers: {
            Accept: 'multipart/form-data',
        },
    },
    update: {
        url: `/businessPartner`,
        method: 'patch',
        headers: {
            Accept: 'multipart/form-data',
        },
    },
    delete: {
        url: `/businessPartner`,
        method: 'delete',
    },
    fileDown: {
        url: `/businessPartner/download`,
        method: 'get',
        responseType: 'blob',
    },
    /*
    update: (id, data) => {
        return request({
            url: `/businessPartner/${id}`,
            method: 'fetch',
            data: data,
        });
    },
    delete: (id) => {
        return request({
            url: `/businessPartner/${id}`,
            method: 'delete',
        });
    },
    */
};

// Greener 정책
const TRANSID_GREENER = {
    get: {
        url: '/greenerPolicy',
        method: 'get',
    },
    update: {
        url: '/greenerPolicy',
        method: 'post',
    },
    getGreenerList: {
        url: '/greener',
        method: 'get',
    },
};
// endregion

//region - [고객센터]
// 고객센터 공지
const TRANSID_CSCENTER_NOTICE = {
    fetch: {
        url: '/noticeVoc',
        method: 'get',
    },
    get: {
        url: '/noticeVoc',
        method: 'get',
    },
    create: {
        url: '/noticeVoc',
        method: 'post',
    },
    update: {
        url: '/noticeVoc',
        method: 'patch',
    },
    delete: {
        url: '/noticeVoc',
        method: 'delete',
    },
    listContents: (param) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        const url = `/noticeVoc/listContents?${paramAry.join('&')}`;
        return request({
            url: url,
            method: 'get',
        });
    },
};

// 1:1상담
const TRANSID_VOC_MGMT = {
    fetch: {
        url: '/voc',
        method: 'get',
    },
    get: {
        url: `/voc`,
        method: 'get',
    },
    update: {
        url: `/voc`,
        method: 'patch',
    },
    create: {
        url: '/voc',
        method: 'post',
    },
    delete: {
        url: `/voc`,
        method: 'delete',
    },
    getVocHistory: ({ vocID }) => {
        return request({
            url: `/voc/history/${vocID}`,
            method: 'get',
        });
    },
    getVocUsers: (param) => {
        return request({
            url: `/voc/user?name=${
                _.isUndefined(param.form.name) ? '' : param.form.name
            }&masking=${param?.masking || 'N'}`,
            method: 'get',
        });
    },
    getVocCoupons: () => {
        return request({
            url: `/voc/coupon`,
            method: 'get',
        });
    },
    getVocStatics: ({ ...params }) => {
        return request({
            url: `/voc/statics`,
            method: 'get',
            params,
        });
    },
    fileDown: {
        url: `/voc/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// FAQ
const TRANSID_FAQ_MGMT = {
    fetch: {
        url: '/faq',
        method: 'get',
    },
    get: {
        url: `/faq`,
        method: 'get',
    },
    update: {
        url: `/faq`,
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    create: {
        url: '/faq',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'post',
    },
    delete: {
        url: `/faq`,
        method: 'delete',
    },
};

// 담당자 문의
const TRANSID_CONTACT_MANAGER = {
    fetch: {
        url: '/manager',
        method: 'get',
    },
};
//endregion

//region - [시스템 관리]
// 권한
const TRANSID_ROLE = {
    fetch: {
        url: '/role',
        method: 'get',
    },
    get: {
        url: `/role`,
        method: 'get',
    },
    update: {
        url: `/role`,
        method: 'patch',
    },
    create: {
        url: '/role',
        method: 'post',
    },
    delete: {
        url: `/role`,
        method: 'delete',
    },
};

// 권한별 메뉴
const TRANSID_ROLE_MENU = {
    get: ({ id }) => {
        return request({
            url: `/role/${id}/acl`,
            method: 'get',
        });
    },
    update: ({ id, param }) => {
        return request({
            url: `/role/${id}/acl`,
            method: 'patch',
            data: param,
        });
    },
};

// 코드
const TRANSID_CODE = {
    fetch: {
        url: `/code`,
        method: 'get',
    },
    get: {
        url: `/code`,
        method: 'get',
    },
    update: {
        url: `/code`,
        method: 'patch',
    },
    create: {
        url: '/code',
        method: 'post',
    },
    delete: {
        url: `/code`,
        method: 'delete',
    },
};

// 메뉴
const TRANSID_MENU = {
    fetch: {
        url: `/menu`,
        method: 'get',
    },
    get: {
        url: `/menu`,
        method: 'get',
    },
    update: {
        url: `/menu`,
        method: 'patch',
    },
    create: {
        url: '/menu',
        method: 'post',
    },
    delete: {
        url: `/menu`,
        method: 'delete',
    },
};

// 메뉴관리 + 프로그램관리 Rel 조회
const TRANSID_MENU_PROGRAM = {
    fetch: ({ id, ...params }) => {
        return request({
            url: `/menu/${id}/program`,
            method: 'get',
            params,
        });
    },
    create: ({ id, param }) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });
        return request({
            url: `/menu/${id}/program`,
            method: 'post',
            data: formData,
        });
    },
    delete: ({ id, param }) => {
        const formData = new FormData();
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    formData.append(key, item);
                });
            } else formData.append(key, param[key]);
        });
        return request({
            url: `/menu/${id}/program`,
            method: 'delete',
            data: formData,
        });
    },
};

// 프로그램
const TRANSID_PROGRAM = {
    fetch: {
        url: '/program',
        method: 'get',
    },
    create: {
        url: '/program',
        method: 'post',
    },
    delete: {
        url: `/program`,
        method: 'delete',
    },
    deleteItem: (param) => {
        const { type, code, state } = param;
        const url = type ? `/user/auth2` : `/user/auth`;
        return request({
            url: url,
            method: 'delete',
            data: { code, state },
        }).then((response) => {
            const { status, data } = response;
            if (200 !== status) {
                throw response;
            }

            const { activationStatus } = data;
            if ('NORMAL' === activationStatus) {
                return response;
            }
            throw response;
        });
    },
};

// 배치
const TRANSID_JOB = {
    fetch: {
        url: '/batch/scheduler/jobs',
        method: 'get',
    },
    get: {
        url: `/batch/scheduler/jobs`,
        method: 'get',
    },
    update: {
        url: `/batch/scheduler/jobs`,
        method: 'patch',
    },
    run: (id) => {
        return request({
            url: `/batch/scheduler/manualrun/${id}`,
            method: 'get',
        });
    },
};

// 배치실행이력
const TRANSID_JOB_HIST = {
    fetch: {
        url: '/batch/scheduler/jobhistory',
        method: 'get',
    },
    get: (Id, param) => {
        return request({
            url: `/batch/scheduler/jobhistory/${Id}`,
            method: 'get',
        });
    },
};
//endregion

//region - [기타]
// RD 연동
const TRANSID_REPORT_DESIGNER_DATA = {
    getTransferRdData: ({ ID }) => {
        const url = `/violation/transfer/${ID}`;
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    getUsagebillRdData: ({ reservationID }) => {
        const url = `/reservation/report/${reservationID}/usage`;
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    getCustomerbillRdData: ({ ID }) => {
        const url = `/vehicle/repair/${ID}`;
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    getContractRdData: ({ reservationID }) => {
        const url = `/reservation/report/${reservationID}/contract`;
        return request({
            url: url,
            method: 'get',
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
    getOperationHistoryRdData: (params) => {
        const url = '/vehicle/operationrd';
        return request({
            url: url,
            method: 'get',
            params,
        }).then((response) => {
            const { status } = response;
            if (200 !== status) {
                throw response;
            }
            return response;
        });
    },
};

// 공급사 등록/조회
const TRANSID_PROVIDER = {
    fetch: {
        url: '/provider',
        method: 'get',
    },
    get: {
        url: '/provider',
        method: 'get',
    },
    create: {
        url: '/provider',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'post',
    },
    update: {
        url: '/provider',
        headers: {
            Accept: 'multipart/form-data',
        },
        method: 'patch',
    },
    delete: {
        url: '/provider',
        method: 'delete',
    },
    getProviderVehicleInfo: (param) => {
        return request({
            url: `/provider/vehicleInfo/${param.ID}`,
            method: 'get',
        });
    },
    // fileDown: {
    //     url: `/provider/download`,
    //     method: 'get',
    //     responseType: 'blob',
    // },
    fileDown: ({ ID }) => {
        // 공급사 차량 목록 엑셀 다운로드
        return request({
            url: `/provider/vehicleInfo/${ID}/download`,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            return response;
        });
    },
};

// VIN 중복체크
const TRANSID_VIN_CHECK = {
    get: (param) => {
        const { VIN } = param;
        return request({
            url: `/vehicle/vin?VIN=${VIN}`,
            method: 'get',
        });
    },
};

// 파일 다운로드
const TRANSID_FILE_DOWN = {
    get: {
        url: `/file/download`,
        method: 'get',
        responseType: 'blob',
    },
};

// 기타 > 내 정보
const TRANSID_MYINFO = {
    fetch: {
        url: `/user/myInfo`,
        method: 'get',
    },
    update: (params) => {
        return request({
            url: `/user/myInfo/update`,
            method: 'patch',
            data: params,
        });
    },
    delete: (params) => {
        return request({
            url: `/user/myInfo/businessProfile`,
            method: 'delete',
        });
    },
};

// 기타 > 내 정보 - 권한이관(슈퍼관리자/부서담당자)
const TRANSID_MYINFO_TRANSFER_ROLE = {
    // 부서원 목록
    fetch: {
        url: `/department/employee`,
        method: 'get',
    },
    // 권한 이관
    create: {
        url: `/user/transfer/operatorRole`,
        method: 'post',
    },
};

// 대시 보드
const TRANSID_DASHBOARD = {
    // jp > 잔여 충전량 부족 알림
    fetch: {
        url: `/dashboard/chargeWarning`,
        method: 'get',
    },
    fileDown: {
        url: '/dashboard/chargeWarning/download',
        method: 'get',
        responseType: 'blob',
    },
    delete: {
        url: '/dashboard/chargeWarning',
        method: 'delete',
    },
    get: {
        url: '/dashBoard',
        method: 'get',
    },
    getContract: (id) => {
        if (!id) return;

        return request({
            url: `/contract/${id}`,
            method: 'get',
        });
    },
    getBusinessNoti: ({ id, role }) => {
        return request({
            url: `/businessNotifi/${id}?noPaging=Y&role=${role}`,
            method: 'get',
        });
    },
    getDashboardSplash: ({language}) => {
        return request({
            url: `/dashboard-splash?langs=${language}`,
            method: 'get',
        });
    },
    getWibleBizBaseInfo: ({ param }) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        return request({
            url: `/dashboard/admin?${paramAry.join('&')}`,
            method: 'get',
        });
    },
    getWibleBizProfileBusiness: ({ param }) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        return request({
            url: `/dashboard/admin/business?${paramAry.join('&')}`,
            method: 'get',
        });
    },
    getWibleBizProfilePersonal: ({ param }) => {
        const paramAry = [];
        Object.keys(param).forEach((key) => {
            if (Array.isArray(param[key])) {
                param[key].forEach((item) => {
                    paramAry.push(`${key}=${item}`);
                });
            } else paramAry.push(`${key}=${param[key]}`);
        });
        return request({
            url: `/dashboard/admin/personal?${paramAry.join('&')}`,
            method: 'get',
        });
    },
};

const TRANSID_FILE = {
    delete: {
        url: `/file`,
        method: 'delete',
    },
};

// 이관신청서
const TRANSID_TRANSFER = {
    // 대시 보드
    get: {
        url: '/violation/transfer',
        method: 'get',
    },
};

const TRANSID_TEMP_LOCAL = {
    // localhost 로그인용 business
    getBusiness: {
        url: '/temp/business?noPaging=Y',
        method: 'get',
    },
    // localhost 로그인용 provider
    getProvider: {
        url: '/temp/provider?noPaging=Y',
        method: 'get',
    },
    // localhost 로그인용 역할
    getRole: {
        url: '/temp/role?noPaging=Y',
        method: 'get',
    },
    // localhost 로그인용 역할
    fetch: {
        url: 'http://localhost:8080/jp/redirect',
        method: 'get',
    },
};

//endregion

export {
    //region - [공통]
    TRANSID_AUTHENTICATION,
    TRANSID_SYSTEM_CODE,
    //endregion

    //region - [차량 관리]
    TRANSID_VEHICLE, // 차량 관리 > 차량 등록/조회
    TRANSID_VEHICLE_UPLOAD, // 차량 일괄등록
    TRANSID_VEHICLE_UPLOAD_HISTORY, // 차량 일괄등록 이력 조회
    TRANSID_VEHICLE_CHECK, // 차량번호 중복확인
    TRANSID_VEHICLE_MONITORING, // 차량 관리 > 차량 관제
    TRANSID_COMMAND, // 차량 관리 > 차량 관제 > 차량 제어
    TRANSID_VEHICLE_DAILY_OPERATION, // 차량 관리 > 차량 운행일지
    TRANSID_VEHICLE_MODEL, // 차량 관리 > 차종(차량모델)
    TRANSID_TERMINAL, // 차량 관리 > 단말기
    TRANSID_TERMINAL_VERIFICATION, // 차량 관리 > 단말기 창착 검증
    TRANSID_REFUEL, // 차량 관리 > 충전/주유 이력
    TRANSID_TRANSIT, // 차량 관리 > 하이패스 이력
    TRANSID_VEHICLE_REPAIR, // 차량 관리 > 사고수리 이력
    TRANSID_VEHICLE_REPAIR_RESV,
    TRANSID_VEHICLE_MAINTENANCE, // 차량 관리 > 유지관리/정비 이력
    TRANSID_TRADE, // 차량 관리 > 매각
    TRANSID_TRADE_VEHICLE_LIST, // 차량 관리 > 매각 - 차량 조회
    TRANSID_CONTROL_HISTORY, // 차량 관리 > 차량제어 이력
    TRANSID_CONTROL_HISTORY_SERVER, // 차량 관리 > 차량제어 이력(서버)
    TRANSID_CONTROL_HISTORY_BLE, // 차량 관리 > 차량제어 이력(BLE)
    // endregion

    //region - [위블존 관리]
    TRANSID_LOCATION, // 위블존 관리 > 위블존 등록/조회
    // endregion

    //region - [대여/결제 관리]
    TRANSID_RESERVATION_STATUS, // 대여 현황
    TRANSID_RESERVATION, // 대여 관리
    TRANSID_RESERVATION_STATS, // 대여 관리 통계
    TRANSID_AVAILABLE_VEHICLE, // 대여가능차량 조회
    TRANSID_AVAILABLE_CHARGETYPE, // 대여연장 - 차량요금타입 조회
    TRANSID_HOLIDAY, // 대여연장 - 휴일
    TRANSID_POI_RELATION, // 대여연장 - 이어타기
    TRANSID_POI_PERSONAL_RELATION, // 대여연장 - 개인이어타기
    TRANSID_ADDGOODS_POI, // 부가상품 대여요금 계산
    TRANSID_CHARGE_CAL, // 결제요금조회
    TRANSID_AVAILABLE_COUPON, // 적용가능쿠폰 조회
    TRANSID_CLAIM, // 미수금 회원 조회
    TRANSID_RESERVATION_PRODUCT, // 대여 내역
    TRANSID_RESERVATION_PRODUCT_STATS, // 대여 내역 통계
    TRANSID_CARD,
    TRANSID_SUBSCRIBE, // 구독 내역
    TRANSID_PAYMENT, // 결제 내역
    TRANSID_PAYMENT_CARD, // 결제 카드
    TRANSID_HIPASS, // 하이패스 카드
    TRANSID_CHARGECARD, // 충전제휴 카드
    // endregion

    //region - [회원사 관리]
    TRANSID_PLAIN_BUSINESS,
    TRANSID_BUSINESS, // 회원사/계약
    TRANSID_BUSINESS_ADMIN_INVITE,
    TRANSID_BUSINESS_CONTRACT,
    TRANSID_CONTRACT,
    TRANSID_CUSTOMER_SURVICE, // 기업 상담이력
    TRANSID_CUSTOMER_SERVICE_HISTORY, // 기업 상담이력 히스토리
    TRANSID_CUSTOMER_SURVICE_CONTRACT, // 계약 > 기업 상담등록
    // endregion

    //region - [회원/부서 관리]
    TRANSID_CUSTOMER_USER, // 회원
    TRANSID_CUSTOMER_USER_MESSENGER, // 회원 > 1:1 문자발송
    TRANSID_ACTUALISSUED_COUPON, //  쿠폰 실방행매수
    TRANSID_ACTUALUSER_COUPON, // 쿠폰 실사용유저
    TRANSID_CUSTOMER_WITHDRAW_USER, // 회원 > 탈퇴회원
    TRANSID_BUSINESS_MEMBER, // 임직원 조회
    TRANSID_BUSINESS_INVITE, // 임직원 등록
    TRANSID_BUSINESS_INVITE_HISTORY, // 임직원 등록 업로드 이력
    TRANSID_DEPARTMENT, // 부서
    TRANSID_EMPLOYEE_IN_DEPARTMENT, // 부서 담당자 등록
    TRANSID_BUSINESS_OPERATOR, // 회원사 권한
    TRANSID_LICENSE_CHECK_LIST, // 운전면허 검증이력
    TRANSID_PENALTY, // 페널티
    TRANSID_PENALTY_POLICY, // 페널티 정책
    TRANSID_DRIVER_LICENSE_INFO, // 운전면허 조회
    // endregion

    //region - [매출/정산 관리]
    TRANSID_SALES, // 매출/정산 관리 > 매출
    TRANSID_COUPON_SALES, // 매출/정산 관리 > 쿠폰 매출
    TRANSID_CALCULATION, // 매출/정산 관리 > 정산
    TRANSID_COUPON, // 쿠폰
    TRANSID_RECEIVBLE, // 미수금
    TRANSID_VIOLATION_MGMT, // 과태료
    // endregion

    //region - [상품 관리]
    TRANSID_CHARGE, // 요금제
    TRANSID_INSURANCEGROUP, // 보험료
    TRANSID_ADDITION_GOODS, // 부가상품
    // endregion

    //region - [콘텐츠 관리]
    TRANSID_NOTICE, // 공지/이벤트
    TRANSID_NOTICE_COMPANY, // 공지/이벤트 회원사 정보
    TRANSID_GUIDE_LOCATION, // 위블존 이용안내
    TRANSID_GUIDE_CONTENTS, // 앱 콘텐츠 관리
    TRANSID_MODEL_INFO, // 차종별 이용안내
    TRANSID_BANNER_MGMT, // 배너/스플래시/팝업
    TRANSID_MSG_TEMPLATE, // 알림 템플릿
    TRANSID_HISTORY_MSG_TEMPLATE, // 알림발송이력
    TRANSID_PASSIVE_MESSAGE, // 수동메시지 관리
    TRANSID_TERMS, // 약관관리
    TRANSID_TERMS_HISTORY, // 약관관리 히스토리
    // endregion

    //region - [운영 관리]
    TRANSID_APP_VERSION, // 버전
    TRANSID_OPERATOR, // 어드민
    TRANSID_BUSINESS_PARTNER, // 위탁사
    TRANSID_GREENER, // Greener 정책
    //endregion

    //region - [고객센터]
    TRANSID_CSCENTER_NOTICE, // 고객센터 공지
    TRANSID_VOC_MGMT, // 1:1상담
    TRANSID_FAQ_MGMT, // FAQ
    TRANSID_CONTACT_MANAGER, // 담당자 문의
    // endregion

    //region - [시스템 관리]
    TRANSID_ROLE, // 권한
    TRANSID_ROLE_MENU, // 권한별 메뉴
    TRANSID_CODE, // 코드
    TRANSID_MENU, // 메뉴
    TRANSID_MENU_PROGRAM, // 메뉴관리 + 프로그램관리 Rel 조회
    TRANSID_PROGRAM, // 프로그램
    TRANSID_JOB, // 배치
    TRANSID_JOB_HIST, // 배치실행이력
    // endregion

    //region - [기타]
    TRANSID_REPORT_DESIGNER_DATA, // RD 연동
    TRANSID_PROVIDER, // 공급사관리
    TRANSID_VIN_CHECK, // VIN 중복체크
    TRANSID_FILE_DOWN, // 파일 다운로드
    TRANSID_MYINFO, // 내 정보
    TRANSID_MYINFO_TRANSFER_ROLE, // 내 정보 - 권한이관(슈퍼관리자/부서담당자)
    TRANSID_DASHBOARD,
    TRANSID_FILE,
    TRANSID_TRANSFER, // 이관신청서

    TRANSID_TEMP_LOCAL, // localhost 로그인용
    // endregion
};
